import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { AllInvoice, AllCompany } from "../../../helper/api";
import _ from "lodash";
function DataTables() {
  const [data, setData] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [company, setCompany] = useState([]);

  const columns = [
    {
      Header: "ID",
      accessor: "invoice_id",
    },
    {
      Header: "公司",
      accessor: "company",
    },

    {
      Header: "車牌號碼",
      accessor: "car_number",
    },

    {
      Header: "呎碼",
      accessor: "size",
    },

    {
      Header: "貨櫃編號",
      accessor: "container_number",
    },

    {
      Header: "Edit",
      accessor: "edit",
      align: "center",
    },

    {
      Header: "Print",
      accessor: "print",
      align: "center",
    },
  ];

  function edit(id) {
    return (
      <Link to={"/admin/invoice/edit/" + id} style={{ float: "right" }}>
        <MDButton color="info">更改</MDButton>
      </Link>
    );
  }

  function print(id) {
    return (
      <Link to={"/admin/invoice/print/" + id} style={{ float: "right" }}>
        <MDButton color="success">列印</MDButton>
      </Link>
    );
  }

  useEffect(() => {
    if (company.length > 0 && company.length > 0) {
      var tmp = [];

      invoice.forEach((item) => {
        //console.log(item)
        for (var i = 0; i < company.length; i++) {
          // look for the entry with a matching `code` value
          if (company[i].id == item.c_id) {
            item.company = company[i].c_name;
            item.print = print(item.invoice_id);
            item.edit = edit(item.invoice_id);
            return tmp.push(item);
          }
        }
      });

      setData(_.orderBy(tmp, ["id"], ["desc"]));
    }
  }, [invoice, company]);

  useEffect(() => {
    if (invoice.length == 0) {
      AllInvoice()
        .then((res) => {
          setInvoice(res.data.responses);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (company.length == 0) {
      AllCompany()
        .then((res) => {
          setCompany(res.data.responses);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              發票
            </MDTypography>
            <MDTypography variant="button" color="Header">
              Invoice
            </MDTypography>
            <Link to="/admin/invoice/add" style={{ float: "right" }}>
              <MDButton color="primary">新增發票</MDButton>
            </Link>
          </MDBox>
          <DataTable table={{ rows: data, columns }} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataTables;
