/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/cstop";
import ReceiptList from "layouts/pages/receipt/List";
import ReceiptForm from "layouts/pages/receipt/Form";
import ReceiptPrint from "layouts/pages/receipt/Print";
import InvoiceList from "layouts/pages/invoice/List";
import InvoiceForm from "layouts/pages/invoice/Form";
import InvoicePrint from "layouts/pages/invoice/Print";
import StatmentList from "layouts/pages/statment/List";
import MonthlyInvoiceList from "layouts/pages/monthlyInvoice/List";
import CompanyList from "layouts/pages/company/List";
import CompanyForm from "layouts/pages/company/Form";
import DespriptionList from "layouts/pages/despription/List";
import PdfList from "layouts/pages/pdf/List";
import SignInIllustration from "layouts/authentication/sign-in/illustration";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/avater.png";

const routes = [
  {
    type: "collapse",
    name: localStorage.getItem("username"),
    key: "username",
    icon: <MDAvatar src={profilePicture} alt="..." size="sm" />,
    collapse: [
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "控制台",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "首頁",
        key: "analytics",
        route: "/dashboards/top",
        component: <Analytics />,
      },
    ],
  },
  {
    type: "collapse",
    name: "發票",
    key: "invoice",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    collapse: [
      {
        name: "發票列表",
        key: "invoiceList",
        route: "/admin/invoice",
        component: <InvoiceList />
      },
      {
        name: "新增發票",
        key: "invoiceAdd",
        route: "/admin/invoice/add",
        component: <InvoiceForm />
      }
    ],
  },
  {
    type: "collapse",
    name: "月結單",
    key: "statment",
    icon: <Icon fontSize="medium">event_repeat</Icon>,
    collapse: [
      {
        name: "每月發票",
        key: "monthlyInvoiceList",
        route: "/admin/invoice/monthly",
        component: <MonthlyInvoiceList />
      },
      {
        name: "月結單",
        key: "statmentList",
        route: "/admin/statment",
        component: <StatmentList />
      },
    ],
  },
  {
    type: "collapse",
    name: "拖運單",
    key: "receipt",
    icon: <Icon fontSize="medium">receipt</Icon>,
    collapse: [
      {
        name: "拖運單列表",
        key: "receiptList",
        route: "/admin/receipt",
        component: <ReceiptList />
      },
      {
        name: "新增拖運單",
        key: "receiptAdd",
        route: "/admin/receipt/add",
        component: <ReceiptForm />
      },
    ],
  },
  {
    type: "collapse",
    name: "公司",
    key: "company",
    icon: <Icon fontSize="medium">business</Icon>,
    collapse: [
      {
        name: "公司列表",
        key: "companyList",
        route: "/admin/company",
        component: <CompanyList />
      },
      {
        name: "新增公司",
        key: "companyAdd",
        route: "/admin/company/add",
        component: <CompanyForm />
      },
    ],
  },
  {
    type: "collapse",
    name: "貨品",
    key: "despription",
    icon: <Icon fontSize="medium">inventory</Icon>,
    collapse: [
      {
        name: "貨品列表",
        key: "despriptionList",
        route: "/admin/despription",
        component: <DespriptionList />
      },
    ],
  },
  {
    type: "collapse",
    name: "PDF",
    key: "pdf",
    icon: <Icon fontSize="medium">picture_as_pdf</Icon>,
    collapse: [
      {
        name: "PDF",
        key: "pdfList",
        route: "/admin/pdf",
        component: <PdfList />
      },
    ],
  },
  {
    type: "hide",
    name: "Hide",
    key: "hide",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Illustration",
            key: "illustration",
            route: "/authentication/login",
            component: <SignInIllustration />,
          },
          {
            name: "Edit",
            key: "invoiceEdit",
            route: "/admin/invoice/edit/:id",
            component: <InvoiceForm />
          },
          {
            name: "Edit",
            key: "receiptEdit",
            route: "/admin/receipt/edit/:id",
            component: <ReceiptForm />
          },
          {
            name: "Edit",
            key: "companyEdit",
            route: "/admin/company/edit/:id",
            component: <CompanyForm />
          },
          {
            name: "Edit",
            key: "receiptPrint",
            route: "/admin/receipt/print/:id",
            component: <ReceiptPrint />
          },
          {
            name: "Edit",
            key: "invoicePrint",
            route: "/admin/invoice/print/:id",
            component: <InvoicePrint />
          },
        ],
      }
    ],
  },
];

export default routes;
