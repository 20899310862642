import React, { useState } from "react";

// @material-ui core components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

export default function List() {

  const [files, setFiles] = useState([]);
  
  const Submit = () => {
    const file = files[0];
    const formData = new FormData();
    formData.append("file", file);
    fetch('https://api2.sunwo.xyz/api/v1/pdf', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: formData
    }).then((res) => {
      if (res.status === 200) {
        res.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          // use file Name from file object
          a.download = file.name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
      } else {
        alert('Error uploading file');
      }
    });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <form>
              <input type="file" id="file-upload" onChange={(e) => setFiles(e.target.files)} />
              <MDButton onClick={() => Submit()}>Upload</MDButton>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
