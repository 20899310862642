import React, { useState, useEffect } from "react";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Print from "./Print";
import Nzh from "nzh";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
// import _ from "lodash";

import { AllCompany, InvoiceGetCompany } from "../../../helper/api";

import moment from "moment";

import { NewBook, AddSheetToBook, SaveXlsx } from "helper/xlsx/xlsxTable";

export default function Edit_Receipt() {
  const [c_id, setC_id] = useState("1");
  const [date_add, setDateAdd] = useState(new Date());

  const [company, setCompany] = useState([]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [all, setAll] = useState(0);
  const [rdy, setRdy] = useState(false);
  const [isExcel, setIsExcel] = useState(false);

  const Submit = () => {
    setRdy(false);
    InvoiceGetCompany(c_id.id, moment(date_add).format("yyyy"), moment(date_add).format("MM"))
      .then((res) => {
        // console.log(res.data.invoice_data)
        let d = res.data.responses;
        setData(d);
        let All = 0;
        let temp = [];
        d.forEach((item) => {
          // console.log(item)
          if (temp[item.invoice_id]) temp[item.invoice_id] = temp[item.invoice_id] + item.price;
          else temp[item.invoice_id] = item.price;
          All += item.price;
          //  console.log(temp[item.invoice_id])
        });
        setAll(All);
        // console.log(temp)
        setTotal(temp);
        // console.log(total)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (company.length == 0) {
      AllCompany()
        .then((res) => {
          setCompany(res.data.responses);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 403) console.log(err);
        });
    }
  }, [company]);

  useEffect(() => {
    console.log(data);
    if (data.length > 0) setRdy(true);
  }, [total]);

  function ToName(id) {
    for (var i = 0; i < company.length; i++) {
      if (company[i].id == id) {
        return company[i].c_name;
      }
    }
  }

  function ToChinese(num) {
    var nzh = new Nzh({
      ch: "零壹貳參肆伍陸柒捌玖", // 数字字符
      ch_u: "個拾佰仟萬億兆京", // 数位单位字符，万以下十进制，万以上万进制，个位不能省略
      ch_f: "負", // 负字符
      ch_d: "", // 小数点字符
      m_u: "元角分厘", // 金额单位
      m_t: "港幣", // 金额前缀
      m_z: "正", // 金额无小数后缀
    });
    var number = nzh.toMoney(num);
    return number;
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function generateExcel() {
    const fn =
      moment(date_add).format("yyyy") +
      moment(date_add).format("MM") +
      ToName(c_id.id) +
      " - Statment.xlsx";
    let bookTmp = NewBook();

    const transfromedData = [];
    data.forEach((item) => {
      if (item.despription_number == 1) {
        transfromedData.push({
          date_add: item.date_add,
          invoice_id: item.invoice_id,
          price: numberWithCommas(Number.parseFloat(total[item.invoice_id]).toFixed(2)),
          container_number: item.container_number,
        });
      }
    });

    AddSheetToBook({
      book: bookTmp,
      data: [
        {
          date_add: "客戶： ",
          invoice_id: ToName(c_id.id),
        },
        {
          date_add: "發票日期",
          invoice_id: "發票號碼",
          container_number: "貨櫃號碼",
          price: "金額（港幣）",
        },
        ...transfromedData,
        {
          date_add: "",
        },
        {
          date_add: "合計：",
          invoice_id: ToChinese(all),
        },
        {
          date_add: "",
        },
        {
          date_add: "戶口名稱 :",
          invoice_id: "新和貨櫃有限公司",
        },
        {
          date_add: "銀行名稱 : ",
          invoice_id: "中國銀行(香港)有限公司",
        },
        {
          date_add: "戶口號碼 : ",
          invoice_id: "012-382-00010526",
        },
      ],
      sheetName: "Statment",
      skipHeader: true,
    });
    SaveXlsx({
      book: bookTmp,
      fileName: fn,
    });

    setIsExcel(false);
  }
  const checkSubmit = () => {
    return false;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              月結單
            </MDTypography>
            <MDTypography variant="button" color="Header">
              STATMENT
            </MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  value={c_id}
                  onChange={(_, value) => setC_id(value)}
                  getOptionSelected={(option) => option.id == c_id}
                  options={company}
                  getOptionLabel={(option) => option.c_name}
                  renderInput={(params) => (
                    <FormField
                      size="large"
                      {...params}
                      label="公司"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <MDDatePicker
                  onChange={([date]) => {
                    setDateAdd(date);
                  }}
                  value={date_add}
                  options={{
                    maxDate: new Date(),
                    dateFormat: "Y-m",
                    altInput: true,
                    altFormat: "Y-m",
                    ariaDateFormat: "Y-m",
                  }}
                  input={{ placeholder: "日期" }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <MDButton
                  disabled={checkSubmit()}
                  variant="gradient"
                  color="info"
                  size="large"
                  onClick={() => {
                    Submit();
                  }}
                >
                  提交
                </MDButton>
              </Grid>
              <Grid item xs={12} sm={2}>
                <MDButton
                  disabled={data.length === 0}
                  variant="gradient"
                  color="info"
                  size="large"
                  onClick={() => {
                    generateExcel();
                  }}
                >
                  Excel
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
        {rdy && !isExcel ? <Print data={data} company={company} all={all} total={total} /> : null}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
