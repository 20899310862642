import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import {
  GetCompanyById,
  NewCompany,
  EditCompany,
} from "../../../helper/api";

export default function Edit_Receipt() {
  let { id } = useParams();
  const navigate = useNavigate();
    
  const [c_name, setC_name] = useState("");
  const [c_address, setC_address] = useState("");
  const [contact, setContact] = useState("");
  const [bankAc, setBankAc] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");

  const Submit = () => {
    let data = {
      c_address: c_address,
      c_name: c_name,
      contact: contact,
      bank_ac: bankAc,
      phone: phone,
      phone2: phone2
    };

    console.log(data);
    if (id === undefined) {
      NewCompany(data)
        .then((res) => {
          if (res.data.success) navigate("/admin/company");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      EditCompany(id, data)
        .then((res) => {
          if (res.data.success) navigate("/admin/company");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      GetCompanyById(id)
        .then((res) => {
          let d = res.data.responses;
          console.log(d);
          setContact(d.contact)
          setC_address(d.c_address)
          setC_name(d.c_name)
          setBankAc(d.bank_ac)
          setPhone(d.phone)
          setPhone2(d.phone2)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const checkSubmit = () => {
    if ((c_name !== "")){
        return false
    }else{
        return true
    }
}

  return (
    <MDBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={2} />
        <Grid item xs={12} lg={9}>
          <Card sx={{ overflow: "visible" }}>
            <MDBox p={3}>
              <MDTypography variant="h5">
                {id === undefined ? <h1> 新增公司 </h1> : <h1> 更改公司 </h1>}
              </MDTypography>
              <Link to="/admin/company" style={{ float: "right" }}>
                <MDButton color="primary">返回</MDButton>
              </Link>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormField
                    size="large"
                    value={c_name}
                    label="公司名稱"
                    placeholder="輸入公司名稱"
                    onChange={(event) => setC_name(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    size="large"
                    value={c_address}
                    label="公司地址"
                    placeholder="輸入公司地址"
                    onChange={(event) => setC_address(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormField
                    size="large"
                    value={bankAc}
                    label="銀行戶口"
                    placeholder="輸入銀行戶口"
                    onChange={(event) => setBankAc(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    size="large"
                    value={contact}
                    label="聯絡人"
                    placeholder="聯絡人"
                    onChange={(event) => setContact(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    size="large"
                    value={phone}
                    label="電話"
                    placeholder="輸入電話"
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    size="large"
                    value={phone2}
                    label="第二電話"
                    placeholder="輸入第二電話"
                    onChange={(event) => setPhone2(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <MDButton
                        disabled={checkSubmit()}
                        variant="gradient"
                        color="info"
                        size="large"
                        fullWidth
                        style={{ float: "right" }}
                        onClick={() => Submit()}
                    >
                        提交
                    </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
